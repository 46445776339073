<template>
  <b-form-group
    :label="label"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="label"
      :vid="name"
      :rules="rules"
    >
      <b-form-input
        :id="name"
        ref="inputRef"
        type="text"
        :value="formattedValue"
        :state="errors.length > 0 ? false:null"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>

</template>
<script>
import { useCurrencyInput } from 'vue-currency-input'
import { ValidationProvider } from 'vee-validate'
import { watch } from '@vue/composition-api'

export default {
  name: 'CurrencyInput',
  components: {
    ValidationProvider,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    options: Object,
    value: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    delay: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    value(data) {
      this.inputValue = data
    },

  },
  setup(props) {
    const options = {
      currency: 'BRL',
      currencyDisplay: 'hidden',
      valueRange: undefined,
      precision: 2,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: true,
      exportValueAsInteger: false,
      autoSign: false,
      useGrouping: true,
    }
    const { formattedValue, inputRef, setValue } = useCurrencyInput(options)
    watch(() => props.value, value => { // Vue 2: props.value
      setValue(value)
    })
    return { inputRef, formattedValue }
  },
}
</script>
